<template>
  <div class="components-import">
    <el-upload
        name="uploadFile"
        :action="BaseUrl + importUrl"
        :headers="{ Authorization: getAccessToken() }"
        :on-success="handleSuccess"
        :before-upload="beforeImportFile"
        :on-error="handleError"
        :show-file-list="false">
        <el-button type="warning" size="small">{{ buttonTxt }}</el-button>
        <div slot="tip" class="el-upload__tip" v-if="tips">{{ tips }}</div>
    </el-upload>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log, getAccessToken } from "@/common/utils";
import { BaseUrl } from "@/common/config";
export default {
  name: "import",
  props: {
    tips: {
      type: String,
      default: "",
    },
    importUrl: {
      type: String,
      default: "",
    },
    buttonTxt: {
      type: String,
      default: "导入数据",
    },
  },
  data() {
    return {
      BaseUrl,
      importLoading: null,
    };
  },
  methods: {
    getAccessToken,
    beforeImportFile(file) {
      if (!file.name.includes('xls')) {
          this.$message.warning('只能上传excel文件')
          return
      }
      this.importLoading = this.$loading({
          lock: true,
          text: '文件上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    handleError(){
      if(this.importLoading) this.importLoading.close()
      this.$message.warning('文件上传失败');
    },
    handleSuccess(response) {
      if (response.code == 0) {
        this.$message.success('文件上传成功');
        this.$emit('finish')
      }else{
        this.$message({
          message:response.message,
          type:'error',
          duration:10000,
          showClose:true
        });
      }
      if(this.importLoading) this.importLoading.close()
    },
  },
};
</script>