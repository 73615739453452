<template>
  <div>
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-select clearable filterable v-model="queryParams.authenSta" placeholder="请选择审核状态" @change="resetPageAndRefresh">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已认证" :value="2"></el-option>
          <el-option label="不通过" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.userName" clearable placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.account" clearable placeholder="请输入学号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <base-import importUrl="net/admin/importUser/import" buttonTxt="导入学生信息" @finish="refresh" v-if="OA.includes('jcxx:appyhgl:lead')"></base-import>
      </el-form-item>
      <el-form-item>
        <el-button v-if="OA.includes('jcxx:appyhgl:lead')" size="small" class="el-icon-download"><el-link :href="BaseUrl + 'net/admin/importUser/noLogin/downModule'" class="button_download">下载模板</el-link></el-button>
      </el-form-item>
      <el-form-item class="fr">
        <el-button size="small" icon="el-icon-plus" @click="afterHandleFrom(),handleForm(false)" v-if="OA.includes('jcxx:appyhgl:add')">新增</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div v-if="appTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="appList" :total="appTotal" :loading="tLoading" v-if="appTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column prop="extend.passport" show-overflow-tooltip label="账号"></el-table-column>
        <el-table-column prop="extend.mobile" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column prop="extend.userName" show-overflow-tooltip label="姓名"></el-table-column>
        <el-table-column prop="extend.schoolName" show-overflow-tooltip label="学校"></el-table-column>
        <el-table-column prop="fade" show-overflow-tooltip label="学院"></el-table-column>
        <el-table-column prop="major" show-overflow-tooltip label="专业"></el-table-column>
        <el-table-column prop="extend.typeName" show-overflow-tooltip label="用户角色">
        </el-table-column>
        <el-table-column prop="account" show-overflow-tooltip label="学号/工号"></el-table-column>
        <el-table-column prop="sex" show-overflow-tooltip label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex==1">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column prop="authenSta" show-overflow-tooltip label="认证状态">
          <template slot-scope="scope">
            <span v-if="scope.row.authenSta==0">未认证</span>
            <span v-if="scope.row.authenSta==1">待审核</span>
            <span v-if="scope.row.authenSta==2">已认证</span>
            <span v-if="scope.row.authenSta==3">不通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="subTime" show-overflow-tooltip label="提交时间"></el-table-column>
        <el-table-column prop="authenSta" show-overflow-tooltip label="用户来源">
          <template slot-scope="scope">
            <span v-if="scope.row.platform==2">>校园大赛pc</span>
            <span v-else>菁星校园公众号</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="handleRole(scope.row)" title="分配角色"><img class="loginHead" src="@/assets/images/powers.svg" alt=""></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row, afterHandleFrom)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="scope.row.authenSta == 1 && OA.includes('jcxx:appyhgl:auth')" size="mini" @click="checkVisible = true,handleDetails(scope.row), handleAuth(scope.row)" type="warning" >审核</el-button>
            <el-button v-if="(scope.row.authenSta == 0||scope.row.authenSta == 2||scope.row.authenSta == 3) && OA.includes('jcxx:appyhgl:auth')" size="mini" @click="checkVisible = false,handleDetails(scope.row), handleAuth(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
    
    <el-dialog :title="checkVisible?'审核':'审核详情'" :visible.sync="bVisible" width="65%" :close-on-click-modal="false" :before-close="afterHandleAuth">
      <el-form label-width="150px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="姓名：" prop="extend.userName" class="inlineForm w450">
          <span v-text="details.extend.userName"></span>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idCard" class="inlineForm w450">
          <span v-text="details.idCard"></span>
        </el-form-item>
        <el-form-item label="学号/工号：" prop="account" class="inlineForm w450">
          <span v-text="details.account"></span>
        </el-form-item>
        <el-form-item label="学校：" prop="extend.schoolName" class="inlineForm w450">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="学院：" prop="fade" class="inlineForm w450" v-if="details.fade">
          <span v-text="details.fade"></span>
        </el-form-item>
        <el-form-item label="专业：" prop="major" class="inlineForm w450" v-if="details.major">
          <span v-text="details.major"></span>
        </el-form-item>
        <el-form-item label="用户角色：" prop="authenSta" class="inlineForm w450">
          {{details.extend.typeName}}
        </el-form-item>
        <el-form-item label="入学年份：" prop="startYear" class="inlineForm w450" v-if="details.startYear">
          <span v-text="details.startYear"></span>
        </el-form-item>
        <!-- <el-form-item label="宿舍：" prop="regDate" class="inlineForm w450" v-if="details.regDate">
          <span v-text="details.regDate"></span>
        </el-form-item> -->
        <el-form-item label="用户注册时间：" prop="regDate" class="inlineForm w450" v-if="details.regDate">
          <span v-text="details.regDate"></span>
        </el-form-item>
        <el-form-item label="提交审核时间：" prop="subTime" class="inlineForm w450">
          <span v-text="details.subTime"></span>
        </el-form-item>
        <el-form-item label="审核时间：" prop="checkTime" class="inlineForm w450" v-if="!checkVisible">
          <span v-text="details.checkTime"></span>
        </el-form-item>
        <el-form-item label="用户来源：" prop="platform" class="inlineForm w450" v-if="details.platform">
          <span v-if="details.platform==2">>校园大赛pc</span>
          <span v-else>菁星校园公众号</span>
        </el-form-item>
        <el-form-item label="审核状态：" prop="authenSta" class="inlineForm w450" v-if="details.authenSta == 1||details.authenSta == 2||details.authenSta == 0">
          <span v-if="details.authenSta == 0">未认证</span>
          <span v-if="details.authenSta == 1">待审核</span>
          <span v-if="details.authenSta == 2">已认证</span>
        </el-form-item>
        <el-form-item label="不通过原因：" prop="reason" class="inlineForm w450" v-if="details.authenSta == 3&& !checkVisible">
          <span v-text="details.reason"></span>
        </el-form-item>
        <el-form-item label="身份证正面照片：" prop="idcardPosimg" class="inlineForm w450">
          <el-image :src="details.idcardPosimg"></el-image>
        </el-form-item>
        <el-form-item label="身份证反面照片：" prop="idcardSideimg" class="inlineForm w450">
          <el-image :src="details.idcardSideimg"></el-image>
        </el-form-item>
        <el-form-item label="相关证件照片：" prop="stucardPosimg" class="inlineForm w450">
          <el-image :src="details.stucardPosimg"></el-image>
        </el-form-item>
      </el-form>
      <el-form label-width="150px" ref="formCheckFileds" v-if="checkVisible" :model="reviewForm" :rules="formRules" size="small" style="width:100%">
        <el-form-item label="实名审核：" prop="authenSta" style="width:100%">
          <el-radio-group v-model="reviewForm.authenSta">
            <el-radio :label="item.value" v-for="(item,index) in authOptions" :key="index">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="不通过原因：" v-if="reviewForm.authenSta == 3" prop="reason">
          <el-input v-model="reviewForm.reason" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="afterHandleAuth">取消</el-button>
          <el-button size="small" type="primary" @click="submitAuth">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑用户信息' : '添加用户信息'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="姓名" prop="userName">
          <el-input v-model="form.userName" placeholder="请输入真实姓名" clearable class="edit_input"></el-input>
        </el-form-item>
        <el-form-item label="用户角色" prop="accountType">
          <el-select placeholder="请选择用户角色" clearable filterable class="edit_input" v-model="form.accountType">
            <el-option v-for="item in roleOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCard">
          <el-input v-model="form.idCard" placeholder="请输入身份证号码" clearable class="edit_input"></el-input>
        </el-form-item>
        <el-form-item label="学校" prop="schoolId">
          <el-select placeholder="请选择学校" clearable filterable class="edit_input" v-model="form.schoolId">
            <el-option v-for="item in schoolType" :label="item.schoolName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学院" prop="fade">
          <el-input v-model="form.fade" placeholder="请输入学院" clearable class="edit_input"></el-input>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input v-model="form.major" placeholder="请输入专业" clearable class="edit_input"></el-input>
        </el-form-item>
        <el-form-item label="学号" prop="account">
          <el-input v-model="form.account" placeholder="请输入学号" clearable class="edit_input"></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户角色" prop="startYear">
          <el-select placeholder="请选择用户角色" clearable filterable class="edit_input" v-model="form.startYear">
            <el-option v-for="item in yearOptions" :key="item.id" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="入学年份" prop="startYear">
          <el-select placeholder="请选择入学年份" clearable filterable class="edit_input" v-model="form.startYear">
              <el-option v-for="item in yearOptions" :key="item.id" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 分配角色 -->
    <el-dialog title="分配角色" :visible.sync="roleVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="roleFileds" :model="roleForm" size="small">
        <el-form-item label="角色分配：">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedRole"  @change="handleCheckedRoleChange">
            <el-checkbox :label="item" v-for="(item, index) in roleUses" :key="index"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="roleVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitRole">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import BaseImport from "@/components/common/BaseImport";
import pageMixin from "@/mixins/pageMixin";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'appUser',
  components: {
    PagedTable,
    BaseImport
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      BaseUrl,
      roleVisible: false,
      roleOptions: [],
      appRoleOptions: [],
      checkVisible: false,
      queryParams: {
        userName: '',
        account: '',
        authenSta: 0,
      },
      checkAll: false,
      checkedRole: [],
      roleUses: [],
      roleForm: {
        userId: null,
        roleIds: ''
      },
      isIndeterminate: true,
      details:{
        id:'',
        idCard:'',
        account:'',
        fade: '',
        major: '',
        reason: '',
        idcardPosimg: '',
        idcardSideimg: '',
        stucardPosimg: '',
        startYear: '',
        checkTime: '',
        subTime: '',
        regDate: '',
        platform: '',
        authenSta: null,
        extend:{
          userName: '',
          typeName: '',
          schoolName: '',
        },
      },
      /*********** 实名 */
      form: {
        id: '',
        userName: '',
        idCard: '',
        schoolId: null,
        account: '',
        fade: '',
        accountType: null,
        major: '',
        idcardPosimg:'',
        idcardSideimg: '',
        stucardPosimg:'',
        startYear: '',
      },
      /*** 实名认证验证 */
      formRules: {
        userName: { required: true, message: '请输入姓名', trigger: 'blur' },
        idCard: { required: true, min: 15, max: 18, message: '请输入正确的身份证号', trigger: 'blur' },
        account: { required: true, message: '请输入学号', trigger: 'blur' },
        schoolId: { required: true, message: '请选择学校', trigger: 'blur' },
        fade: { required: true, message: '请输入学院', trigger: 'blur' },
        major: { required: true, message: '请输入专业', trigger: 'blur' },
        idcardPosimg: { required: true, message: '请上传身份证正面照片', trigger: 'change' },
        idcardSideimg: { required: true, message: '请上传身份证反面照片', trigger: 'blur' },
        stucardPosimg: { required: true, message: '请上传学生证展开照片', trigger: 'blur' },
      },
      schoolType: [],
      yearOptions: [],
      bVisible: false,
      reviewForm: {
        id: null,
        authenSta: '',
        reason: ''
      },
      authOptions: [
        { label: '通过', value: '2' },
        { label: '不通过', value: '3' },
      ],
      formRules: {  
        reason: [{ required: true, message: '请输入不通过原因', trigger: 'blur' }],
        authenSta: [{ required: true, message: '请选择实名审核状态', trigger: 'blur' }],
      },
      pageApi:'membersAppList',
      loadApi: "membersAppLoad",
      editorApi:'membersAppEdit',
      insertApi:'membersAppAdd',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicApp", ["appList", "appTotal"]),
  },
  methods: {
    ...mapActions("basicApp",["membersAppList", "membersAppAdd", "membersAppAuth", "membersAppLoad", "membersAppEdit", "commonDictGetDictFPid", "membersAppRoleSelectAll", "membersAppRoleGrant"]),
    ...mapActions("commonOptions",["getSchoolCombo"]),
    handleCheckAllChange(val) {
      this.checkedRole = val ? this.roleUses : [];
      this.idHandle(this.checkedRole)
      this.isIndeterminate = false;
    },
    handleCheckedRoleChange(value) {
      this.idHandle(value)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.roleOption.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.roleOption.length;
    },
    // 处理id
    idHandle(res){
      this.roleForm.roleIds = ''
      res.forEach(element => {
        this.roleList.forEach(ele => {
          if(element == ele.name){
            this.roleForm.roleIds = this.roleForm.roleIds+ele.id+','
          }
        });
      });
      this.roleForm.roleIds = this.roleForm.roleIds.substring(0, this.roleForm.roleIds.lastIndexOf(','))
    },
    handleAuth(row){
      this.reviewForm.id = row.id
    },
    submitAuth(){
      let obj = { ...this.reviewForm }
      this.$refs.formCheckFileds.validate(async (valid) => {
        if (valid) {
          try{
            await this.$confirm('确认提交实名审核吗？');
            let res = await this.membersAppAuth(obj)
            if(res && res.code == 1){
              this.$message.success(res.data.message)
              return;
            }
            this.$message.success('已提交实名审核')
          }catch(e){
            log(e)
          }finally{
            this.afterHandleAuth()
            this.refresh()
          }
        }
      })
    },
    async handleRole(row) {
      this.roleVisible = true
      this.roleUses = []
      this.roleForm.userId = row.id
      this.checkedRole = []
      try{
        let res = await this.membersAppRoleSelectAll({})
        this.roleList = res
        res.forEach(element => {
          this.roleUses.push(element.name)
          if(element.status == 1){
            this.checkedRole.push(element.name)
          }
        });
        this.roleOption = res
      }catch(error){}
      this.idHandle(this.roleUses)
      this.refresh()
    },
    async submitRole(){
      try{
        let res = await this.membersAppRoleGrant({...this.roleForm})
        if(!!res){
          this.$message.success("分配成功")
        }
      }catch(error){}
      this.roleVisible = false
    },
    afterHandleAuth(){
      // this.$refs.formCheckFileds.resetFields();
      this.bVisible = false
      this.reviewForm.id = null
      this.reviewForm.authenSta = ''
      this.reviewForm.reason = ''
    },
    async afterHandleFrom(row){
      // 获取年级下拉框
      if(!!row){this.form.userName = row.extend.userName}
      try{
        let res = await this.commonDictGetDictFPid({pid: 102001})
        this.roleOptions = res
      }catch(error){}
      let myDate = new Date().getFullYear()
      this.yearOptions= []
      for(let i = 0;i<5;i++){
        this.yearOptions.push(myDate)
        myDate--
      }
    }
  },
  async mounted() {
    this.schoolType = await this.getSchoolCombo({schoolName:''})
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
.w450 { width: 450px; }
.inlineForm { 
  .el-image { 
    width: 200px; 
  } 
}
</style>